import { template as template_e364ecd292c44ec6886e4ad784d98f51 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e364ecd292c44ec6886e4ad784d98f51(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
