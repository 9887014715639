import { template as template_5051efe3c6f24159bad51a9ad63bd040 } from "@ember/template-compiler";
const FKLabel = template_5051efe3c6f24159bad51a9ad63bd040(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
